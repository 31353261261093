import React from 'react'
import GlobalStyle from './styles/global'
import Footer from './components/Footer'
import About from './components/About'
import ProjectsHolder from './components/ProjectsHolder'
import { PageView, initGA } from './services/tracking'

export default class App extends React.Component {
    componentDidMount() {
        initGA('UA-117137602-2')
        PageView()
    }
    render() {
        return (
            <>
                <About />
                <ProjectsHolder />
                <Footer />
                <GlobalStyle />
            </>
        )
    }
}
