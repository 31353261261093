import React from 'react'
import {
    FiLinkedin,
    FiGithub,
    FiMail,
    FiPhone,
    FiInstagram,
} from 'react-icons/fi'

import {Container} from './styles'

export default function About() {
    return (
        <Container>
            <div className='visual'>
                <img src='./images/lucas.jpg' alt='Logo' />
            </div>
            <div className='descriptive'>
                <h1>Lucas Sodré</h1>
                <h3>Developer • Design Gráfico • Web Design • Social Media</h3>

                <br></br>
                <br></br>

                <h4>Developer</h4>

                <p>
                    Desenvolvo aplicações web, sistemas, sites e aplicativos,
                    utilizando a tecnologia ideal para o seu negócio.
                </p>
                <p>
                    Mais de 5 anos de experiência em desenvolvimento, com sites,
                    aplicativos, ecommerce (comércio eletrônico), carteira digital,
                    mini-apps (aplicativos dentro de aplicativos), etc.
                </p>


                <p>
                    Também possuo reconhecimentos de bancas avaliadoras em trabalhos,
                    TCC (Trabalho de Conclusão de Curso), trabalhos avaliativos,
                    projetos premiados e certificações. Além disso, fui 1º lugar do Hackathon
                    Unigranrio (2019.1) e 2º lugar do Hackathon Unigranrio
                    (2019.2).
                </p>

                <hr></hr>

                <br></br>
                <br></br>

                <h4>Design Gráfico e Web Design</h4>

                <p>
                    Criação de logos, cartão de visita, peças gráficas para redes sociais, sites
                    ou impressas, capas e imagens de perfil para redes sociais,
                    design e prototipação de sistes, aplicatiovos e sistemas.
                </p>

                <p>
                    Possuo mais de 10 anos de experiência nas áreas de Design Gráfico e Web Design,
                    com projetos diversos, desde cartões de visitas a logos, peças gráficas digitais
                    e impressas, etc.
                </p>

                <hr></hr>

                <br></br>
                <br></br>

                <h4>Social Media</h4>

                <p>
                    Gerenciamento de redes sociais como Facebook e Instagram. Visualizamos o seu público
                    e buscamos as melhores campanhas para você e seu negócio.
                </p>

                <p>
                    Possuo mais de 6 anos de experiência gerenciando redes socias de empresas de
                    turismo, dedetização, igreja, fábrica de bolos, empresa de software, etc.
                </p>

                <p>
                    <i>
                        Eu estou disponível para trabalhos freelancer! Sinta-se
                        livre pra entrar em contato comigo.
                    </i>
                </p>
                <div className='links'>
                    <a
                        href='https://www.instagram.com/lucassdr'
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        <FiInstagram />
                        Instagram
                    </a>
                    <a
                        href='https://www.linkedin.com/in/lucassdr/'
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        <FiLinkedin />
                        Linkedin
                    </a>
                    <a
                        href='https://github.com/lucassdr'
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        <FiGithub /> Github
                    </a>
                </div>
                <div className='links'>
                    <a
                        href='tel:21994694792'
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        <FiPhone /> (21) 994 694 792
                    </a>
                    <a
                        href='mailto:lucassdr@outlook.com.br'
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        <FiMail /> lucassdr@outlook.com.br
                    </a>
                </div>
            </div>
        </Container>
    )
}
