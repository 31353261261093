import React, { useState } from 'react'

import { Container } from './styles'
import Project from '../Project'

import { loadProjects } from '../../services/api'

const data = loadProjects()

export default function ProjectsHolder() {
    const [projects] = useState(data)

    return (
        <Container>
            {projects.map((project, i) => (
                <Project key={i} project={project} />
            ))}
        </Container>
    )
}
