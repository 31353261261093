import React from 'react'
import { FiCode } from 'react-icons/fi'
import { Container } from './styles'
import packageJSON from './../../../package.json'

export default function Footer() {
    return (
        <Container>
            <p>
                Desenvolvido com <FiCode /> por{' '}
                <a
                    href='https://github.com/lucassdr'
                    target='_blank'
                    rel='noopener noreferrer'
                >
                    @lucassdr
                </a>
            </p>
            <small>{packageJSON.version}</small>
        </Container>
    )
}
