const { v4: uuidv4 } = require('uuid')

export function loadProjects() {
    return [
        {
            id: uuidv4(),
            name: 'Ana Amorim - Planos de Saúde',
            description:
                'Site para a corretora de planos de saúde Ana Cláudia Amorim.',
            link: 'http://anaamorimplanodesaude.com.br',
            // github: '',
            image: 'anaamorim.jpeg',
            stack: [
                {
                    id: 1,
                    name: 'HTML',
                    icon: 'html.png',
                    // link: 'https://www.w3schools.com/html/',
                },
                {
                    id: 2,
                    name: 'CSS',
                    icon: 'css.png',
                    // link: 'https://www.w3schools.com/css/',
                },
                {
                    id: 3,
                    name: 'JavaScript',
                    icon: 'js.webp',
                    // link: 'https://www.w3schools.com/js/',
                },
                {
                    id: 4,
                    name: 'Google Analytics',
                    icon: 'google-analytics.png',
                    // link: 'https://analytics.google.com/',
                },
                {
                    id: 5,
                    name: 'Email',
                    icon: 'email.webp',
                    // link: 'https://datastudio.google.com/',
                },
                {
                    id: 6,
                    name: 'WhatsApp',
                    icon: 'whatsapp.png',
                    // link: 'https://datastudio.google.com/',
                },
            ],
        },
        {
            id: uuidv4(),
            name: 'Scopex',
            description:
                'Site para a empresa especializada em equipamentos de videocirurgia.',
            link: 'https://www.scopex.com.br',
            // github: '',
            image: 'scopex.png',
            stack: [
                {
                    id: 1,
                    name: 'HTML',
                    icon: 'html.png',
                    // link: 'https://www.w3schools.com/html/',
                },
                {
                    id: 2,
                    name: 'CSS',
                    icon: 'css.png',
                    // link: 'https://www.w3schools.com/css/',
                },
                {
                    id: 3,
                    name: 'JavaScript',
                    icon: 'js.webp',
                    // link: 'https://www.w3schools.com/js/',
                },
                {
                    id: 4,
                    name: 'Google Analytics',
                    icon: 'google-analytics.png',
                    // link: 'https://analytics.google.com/',
                },
                {
                    id: 5,
                    name: 'Email',
                    icon: 'email.webp',
                    // link: 'https://datastudio.google.com/',
                },
                {
                    id: 6,
                    name: 'WhatsApp',
                    icon: 'whatsapp.png',
                    // link: 'https://datastudio.google.com/',
                },
            ],
        },
        {
            id: uuidv4(),
            name: 'Dados do COVID-19',
            description:
                'Uma aplicação simples e leve que exibe informações a respeito do novo Coronavírus (COVID-19) em todos os países e em detalhes o Brasil, seus estados e Distrito Federal. Todas as informações são fornecidas pela Organização Mundial da Saúde e Ministério da Saúde do Brasil.',
            link: 'https://covid19-azure.now.sh',
            // github: '',
            image: 'covid19.png',
            stack: [
                {
                    id: 1,
                    name: 'React',
                    icon: 'react.svg',
                    // link: 'https://reactjs.org/',
                },
                {
                    id: 2,
                    name: 'About Corona - API',
                    icon: 'corona-api.png',
                    // link: 'https://about-corona.net/documentation',
                },
                {
                    id: 3,
                    name: 'Covid Brazil',
                    icon: 'covid_brazil.png',
                    // link: 'https://reactjs.org/',
                },
                {
                    id: 4,
                    name: 'Google Analytics',
                    icon: 'google-analytics.png',
                    // link: 'https://analytics.google.com/',
                },
            ],
        },
        {
            id: uuidv4(),
            name: 'Glayce Sodré',
            description:
                'Logomarca criada para a esteticista Glayce Sodré.',
            link: '',
            github: '',
            image: 'glayce_sodre_estetica.jpeg',
            stack: [
                {
                    id: 1,
                    name: 'PhotoShop',
                    icon: 'photoshop.png',
                },
            ],
        },
        {
            id: uuidv4(),
            name: 'Yasmin Rocha',
            description:
                'Logomarca e cartão de visita criados para a advogada Yasmin Rocha.',
            link: '',
            github: '',
            image: 'yasmin_rocha.jpeg',
            stack: [
                {
                    id: 1,
                    name: 'PhotoShop',
                    icon: 'photoshop.png',
                },
            ],
        },
        {
            id: uuidv4(),
            name: 'Ministério de Louvor Essência',
            description:
                'Identidade visual completa do Ministério de Louvor Essência, da Igreja Metodista Central em Duque de Caxias.',
            link: '',
            github: '',
            image: 'essencia_minLouvor.png',
            stack: [
                {
                    id: 1,
                    name: 'PhotoShop',
                    icon: 'photoshop.png',
                },
                {
                    id: 1,
                    name: 'Illustrator',
                    icon: 'illustrator.png',
                },
            ],
        },
        {
            id: uuidv4(),
            name: 'Só com CEP',
            description:
                'Aplicação que busca a localidade com base no CEP. Muito útil para ter todos os dados da localidade, de forma assertiva e oficial, de acordo com as bases de CEP oficiais do Brasil.',
            link: 'https://busca-cep.now.sh/',
            github: 'https://github.com/lucassdr/busca-cep',
            image: 'socomcep.png',
            stack: [
                {
                    id: 1,
                    name: 'React',
                    icon: 'react.svg',
                    // link: 'https://reactjs.org/',
                },
                {
                    id: 2,
                    name: 'ViaCEP',
                    icon: 'viacep.png',
                    // link: 'https://viacep.com.br/',
                },
            ],
        },
        {
            id: uuidv4(),
            name: 'Previsão do tempo',
            description: 'Veja a previsão do tempo de forma simples e rápida.',
            link: 'https://previsao-do-tempo.now.sh/',
            github: '',
            image: 'previsaodotempo.png',
            stack: [
                {
                    id: 1,
                    name: 'React',
                    icon: 'react.svg',
                    // link: 'https://reactjs.org/',
                },
                {
                    id: 2,
                    name: 'Weather',
                    icon: 'weatherstack.png',
                    // link: 'http://api.weatherstack.com',
                },
            ],
        },
        {
            id: uuidv4(),
            name: 'Notas do Chef',
            description:
                'Aplicativo para criar uma avaliação de locais frequentados da área alimentícia, como: restaurantes, fast-food, delivery, etc. ',
            // link: '-',
            // github: '',
            image: 'notasdochef.png',
            stack: [
                {
                    id: 1,
                    name: 'React',
                    icon: 'react.svg',
                    // link: 'https://reactnative.dev/',
                },
                {
                    id: 2,
                    name: 'React',
                    icon: 'expo.png',
                    // link: 'https://expo.io/',
                },
                {
                    id: 3,
                    name: 'MongoDB',
                    icon: 'mongodb.svg',
                    // link: 'https://www.mongodb.com/',
                },
            ],
        },
        {
            id: uuidv4(),
            name: 'Quando caiu água?',
            description: `Por conta de alguns problemas de falta d'água por alguns moradores, esta aplicação permite o monitoramento manual com a finalidade de gerar evidências a companhia dos dias e horários observados ao fornecimento.`,
            // link: 'https://agua.now.sh/',
            // github: 'https://github.com/lucassdr/quando-caiu-agua-web',
            image: 'quandocaiuagua.png',
            stack: [
                {
                    id: 1,
                    name: 'React',
                    icon: 'react.svg',
                    // link: 'https://pt-br.reactjs.org/',
                },
                {
                    id: 2,
                    name: 'Node',
                    icon: 'nodejs.png',
                    // link: 'https://nodejs.org/en/',
                },
                {
                    id: 3,
                    name: 'MongoDB',
                    icon: 'mongodb.svg',
                    // link: 'https://www.mongodb.com/',
                },
            ],
        },
        {
            id: uuidv4(),
            name: 'MS Dedetiza',
            description:
                'Site para a dedetizadora MS Dedetiza. Totalmente otimizado e responsivo, se adaptando aos computadores, smartphones e até SmartTv.',
            link: 'https://msdedetizacao.com.br/',
            github: '',
            image: 'msdedetiza.png',
            stack: [
                {
                    id: 1,
                    name: 'React',
                    icon: 'react.svg',
                    // link: 'https://pt-br.reactjs.org/',
                },
            ],
        },
        {
            id: uuidv4(),
            name: 'Postagem por #hashtag [EM DESENVOLVIMENTO]',
            description:
                'Aplicação que busca no Instagram postagens de uma determinada #hashtag.',
            // link: 'https://fotos-casamento.now.sh/',
            // github: 'https://github.com/lucassdr/instagram-casamento',
            image: 'fotosporhashtag.png',
            stack: [
                {
                    id: 1,
                    name: 'React',
                    icon: 'react.svg',
                    // link: 'https://pt-br.reactjs.org/',
                },
                {
                    id: 2,
                    name: 'Instagram',
                    icon: 'instagram-logo.png',
                    // link: 'https://www.instagram.com/',
                },
            ],
        },
        {
            id: uuidv4(),
            name: 'Site de casamento - Mylena & Lucas',
            description:
                'Site de casamento desenvolvido para os noivos Mylena e Lucas. Todo o site foi criado de após um briefing realizado com os noivos, onde a identidade visual foi baseada no desejo deles, porém mantendo a fácil usabilidade, responsividade e rapidez.',
            // link: 'http://mylenaelucas.com.br/',
            // github: 'https://github.com/lucassdr/site-casamento-prd',
            image: 'sitemylenaelucas.png',
            stack: [
                {
                    id: 1,
                    name: 'HTML',
                    icon: 'html.png',
                    // link: 'https://www.w3schools.com/html/',
                },
                {
                    id: 2,
                    name: 'CSS',
                    icon: 'css.png',
                    // link: 'https://www.w3schools.com/css/',
                },
                {
                    id: 3,
                    name: 'JavaScript',
                    icon: 'js.webp',
                    // link:
                    //     'https://developer.mozilla.org/pt-BR/docs/Aprender/JavaScript',
                },
                {
                    id: 4,
                    name: 'Google Analytics',
                    icon: 'google-analytics.png',
                    // link: 'https://analytics.google.com/',
                },
            ],
        },
        {
            id: uuidv4(),
            name: 'Site de chá de panela - Mylena & Lucas',
            description:
                'Site de chá de panela desenvolvido para os noivos Mylena e Lucas. Todo o site foi criado de após um briefing realizado com os noivos, onde a identidade visual foi baseada no desejo deles, porém mantendo a fácil usabilidade, responsividade e rapidez.',
            // link: 'https://cha.mylenaelucas.com.br/',
            // github: 'https://github.com/lucassdr/cha-de-panela-frontend',
            image: 'chadepanela-site.png',
            stack: [
                {
                    id: 1,
                    name: 'React',
                    icon: 'react.svg',
                    // link: 'https://pt-br.reactjs.org/',
                },
                {
                    id: 2,
                    name: 'Google Analytics',
                    icon: 'google-analytics.png',
                    // link: 'https://analytics.google.com/',
                },
            ],
        },
        {
            id: uuidv4(),
            name: 'Sistema ADMIN do chá de panela - Mylena & Lucas',
            description:
                'Sistema admin do chá de panela desenvolvido para os noivos Mylena e Lucas. O sistema possui responsividade, rapidez e fluidez no uso. Além disso, todo o fluxo de utilização segue de acordo com a praticidade acompanhada da total segurança via autenticação.',
            // link: 'https://cha-admin.now.sh/',
            // github: 'https://github.com/lucassdr/cha-de-panela-admin',
            image: 'chadepanela-admin.png',
            stack: [
                {
                    id: 1,
                    name: 'React',
                    icon: 'react.svg',
                    // link: 'https://pt-br.reactjs.org/',
                },
                {
                    id: 2,
                    name: 'Node',
                    icon: 'nodejs.png',
                    // link: 'https://nodejs.org/en/',
                },
                {
                    id: 3,
                    name: 'Express',
                    icon: 'express.svg',
                    // link: 'https://expressjs.com/pt-br/',
                },
                {
                    id: 4,
                    name: 'MongoDB',
                    icon: 'mongodb.svg',
                    // link: 'https://www.mongodb.com/',
                },
            ],
        },
        {
            id: uuidv4(),
            name: 'Encurtador de URL',
            description:
                'Quer compartilhar alguma URL, mas não acha ela amigável ou muito grande? Este encurtador promete facilitar a sua vida.',
            // link: 'https://encurtador-de-url.herokuapp.com/',
            // github: 'https://github.com/lucassdr/url-shortener',
            image: 'encurtador.png',
            stack: [
                {
                    id: 1,
                    name: 'Node',
                    icon: 'nodejs.png',
                    // link: 'https://nodejs.org/en/',
                },
                {
                    id: 2,
                    name: 'Express',
                    icon: 'express.svg',
                    // link: 'https://expressjs.com/pt-br/',
                },
                {
                    id: 3,
                    name: 'MongoDB',
                    icon: 'mongodb.svg',
                    // link: 'https://www.mongodb.com/',
                },
                {
                    id: 4,
                    name: 'EJS',
                    icon: 'ejs.png',
                    // link: 'https://ejs.co/',
                },
            ],
        },
    ]
}
