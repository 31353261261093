import React from 'react'
import Stack from './../Stack'
import { Container, StackContainer } from './styles'
import { Event } from '../../services/tracking'

const showProject = e => {
    Event(e.name, "OpenProject", e.name)
}

export default function Project({ project }) {
    return (
        <Container>
            <img src={`./images/${project.image}`} alt='Project' />
            <h3>{project.name}</h3>
            <p>{project.description}</p>

            <StackContainer>
                {project.stack.map((lang, i) => (
                    <Stack data={lang} key={i} />
                ))}
            </StackContainer>

            <div>
                {project.link && (
                    <a
                        onClick={() => showProject(project)}
                        href={project.link}
                        target='_blank'
                        rel='noopener noreferrer'
                        className='link linkWebsite'
                    >
                        Acessar
                    </a>
                )}
                {project.github && (
                    <a
                        href={project.github}
                        target='_blank'
                        rel='noopener noreferrer'
                        className='link linkGithub'
                    >
                        Repositório
                    </a>
                )}
            </div>
        </Container>
    )
}